<template>
  <section class="list container">
    <div id="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>{{ title }}</h2>
        <ToggleArrow v-if="toggle === true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
        </ToggleArrow>
      </div>
      <div v-if="toggleMe && items.length === 0" style="margin-left: 50px">
        <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            {{ i18n['data-table-scripture'].tcNoRecordsFound }}
          </div>
          <div v-if="!!addItems" class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
      </div>
      <div v-if="items.length > 0">
        <header v-if="toggleMe && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(csvUrl, reportName, 'csv')">{{
              i18n['data-table-scripture'].tcExportCSV
            }}</a>
            <a class="btn btn-dark flex-0 mr-2" download @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')">{{
              i18n['data-table-scripture'].tcExportPDF
            }}</a>
            <b-form-input v-model="filter" @input="handleSearchTermChange"
              :placeholder="i18n['data-table-scripture'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div v-if="!!addItems" class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="toggleMe" class="table-responsive">
          <b-table class="dts-table" :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"
            :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sort-icon-left small>
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px">?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(facility_name)="data">
              <a v-if="data.item.plf_org_key && $route.name !== 'auxiliary-placement-dates'" href="#" style="underline"
                @click.prevent="locationStoreKeyAndRedirect(data)">{{ data.value }}</a>
              <div v-else>{{ data.value }}</div>
            </template>
            <template v-slot:cell(institution_type)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(camp_assigned_number)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(member_type)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(city)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(last_presentation_date)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(placement_date)="data">
              <a href="#" style="underline" @click.prevent="storePlacementKeyAndRedirect(data.item)">{{
                data.value
              }}</a>
            </template>
            <template v-slot:cell(quantity_placed)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(speaker)="data">
              <a href="../../user/user-profile/">{{ data.value }}</a>
            </template>
            <div slot="date_qualified" slot-scope="data">
              <span v-if="type(data.value) == 'string'">{{ data.value }}</span>
              <span v-if="type(data.value) === 'object'" :id="notUndefined(data.value.id)"
                :class="{ tooltip_text: data.value.tooltip }">{{ data.value.text }}</span>
              <b-tooltip v-if="data.value.tooltip && type(data.value) === 'object'" :target="data.value.id"
                :title="data.value.tooltip"></b-tooltip>
            </div>

            <span slot="Media" slot-scope="data">
              <div class="icon-box" :class="{ checked: data.value }"></div>
            </span>

            <span slot="History" slot-scope="data">
              <a :href="data.value">i18n['data-table-scripture'].tcView</a>
            </span>

            <template v-slot:cell(documents)="data">
              <div class="d-flex">
                <div style="margin-right: 10px" v-for="(file, index) in data.value" :key="index">
                  <a v-if="file.type === 'pdf'" :href="file.url" title="PDF">
                    <iFile></iFile>
                  </a>
                  <a v-if="file.type === 'csv'" :href="file.url" title="CSV">
                    <iCSV></iCSV>
                  </a>
                  <a v-if="file.type === 'excel'" :href="file.url" title="Excel">
                    <iExcel></iExcel>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </main>
        <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <i-arrow-left />
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div class="lower">{{ i18n['data-table-scripture'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" @click="nextPage" :class="{
            hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage,
          }">
            <i-arrow-right />
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import constantData from '@/json/data.json'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import iCSV from '@/assets/svgs/iCSV.vue'
import iExcel from '@/assets/svgs/iExcel.vue'
import iFile from '@/assets/svgs/iFile.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table-scripture',
  mixins: [calendarMixin, reportDownloadMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-scripture': {
            tcExportCSV: 'Export XLS',
            tcExportPDF: 'Export PDF',
            tcSearch: 'Search',
            tcShow: 'Show',
            tcNoRecordsFound: 'No records were found.',
            tcView: 'View',
          },
        }
      },
    },
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    reportName: {
      type: String,
      default: 'Report',
    },
    addItems: {
      type: Object,
      default: {
        text: 'Add',
        display: true,
        action: '#',
      },
    },
    hideHeader: { type: Boolean, default: false },
    title: String,
    toggle: Boolean,
    csvUrl: String,
    pdfUrl: String,
  },
  data() {
    return {
      // Placement Table Settings
      sortBy: 'Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      toggleMe: true,
    }
  },
  methods: {
    ...mapActions({
      clearSelectedEvent: 'eventCalendar/clearSelected',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSearchTerm: 'search/setSearchTerm',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedOrgTypeKey: 'user/setSelectedOrgTypeKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    log(item) { },
    type(val) {
      return typeof val
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async locationStoreKeyAndRedirect(data) {
      await this.setSelectedLocationKey(data.item.plf_org_key)
      await this.setSelectedOrgTypeKey(data.item.institution_type_key)
      this.$router.push({ name: 'location-profile' })
    },
    async handleSearchTermChange() {
      await this.setSearchTerm({ scriptureTerm: this.filter })
    },
    individualStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data)
      this.$router.push('/programs/mb/member-profile/')
    },
  },
  created() {
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.scriptureTerm
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      searchTerms: 'search/searchTerms',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    rows() {
      return this.items ? this.items.length : 0
    },
  },
  components: {
    iArrowLeft: iArrowLeft,
    iArrowRight: iArrowRight,
    iCSV: iCSV,
    iExcel: iExcel,
    iFile: iFile,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
  padding: 30px 32px 15px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}

.lower {
  text-transform: lowercase;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.dts-table {
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  tr {
    &:hover {
      td {
        background-color: $yellow-100;
        color: $primary-color;
      }
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  th,
  td {
    // padding: 19px 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-top: none;
    vertical-align: middle;
    color: $gray-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    @include breakpoint(sm) {
      font-size: 18px; // Add k
      padding-right: 15px !important;
    }

    &:first-of-type {
      padding-left: 35px !important;

      @include breakpoint(sm) {
        // Add k
        padding-left: 23px !important; // Add k
      }
    }

    &:last-of-type {
      padding-right: 35px !important;

      @include breakpoint(sm) {
        // Add k
        padding-right: 20px !important; // Add k
      }
    }

    // Leaving these in for now so we can come back
    // and start tweaking as we make Mobile a priority
    // &:nth-of-type(1) {
    //   width: 350px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 350px;
    //   }
    // }
    // &:nth-of-type(2) {
    //   width: 140px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 140px;
    //   }
    // }
    // &:nth-of-type(3) {
    //   width: 155px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 155px;
    //   }
    // }
    // &:nth-of-type(4) {
    //   width: 170px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 170px;
    //   }
    // }
    // &:nth-of-type(5) {
    //   width: 160px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 160px;
    //   }
    // }
    // &:nth-of-type(6) {
    //   width: 185px;
    //   @include breakpoint(sm) {
    //     min-width: inherit; // Add k
    //     // min-width: 185px;
    //   }
    // }
    &:after {
      display: inline-block !important;
      position: static !important;
      padding: 0 !important;
      margin-left: 10px !important;
    }

    .tooltip_text {
      font-weight: 700;
    }
  }

  th.locname-left {
    width: 250px;
  }

  th.locname-left.b-table-sort-icon-left,
  th.date-left.b-table-sort-icon-left {
    background-position: left 20px center !important;
  }

  th {
    color: $primary-color;

    &:after {
      color: $primary-color;
    }

    &[aria-sort='none'] {
      color: $gray-300;

      &:after {
        color: $gray-300;
      }
    }
  }

  td {}

  a {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
    }
  }
}
</style>
